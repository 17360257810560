import ApiService from './api.service';

export const TimeCardActivityUserService = {
  query(params) {
    return ApiService.query('time_card_activity_users', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('time_card_activity_users', slug);
  },
  create(params) {
    return ApiService.post('time_card_activity_users', { time_card_activity_user: params });
  },
  update(slug, params) {
    return ApiService.update('time_card_activity_users', slug, { time_card_activity_user: params });
  },
  destroy(slug) {
    return ApiService.delete(`time_card_activity_users/${slug}`);
  },
  traineeRoleReport(params) {
    return ApiService.post('time_card_activity_users/trainee_role_report', params);
  },
  traineeReport(params) {
    return ApiService.post('time_card_activity_users/trainees_report', params);
  },
  coachedReport(params) {
    return ApiService.post('time_card_activity_users/coached_trained_report', params);
  },
};

export default TimeCardActivityUserService;
