import ApiService from './api.service';

export const TimeCardActivityService = {
  query(params) {
    return ApiService.query('time_card_activities', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('time_card_activities', slug);
  },
  create(params) {
    return ApiService.post('time_card_activities', { time_card_activity: params });
  },
  update(slug, params) {
    return ApiService.update('time_card_activities', slug, { time_card_activity: params });
  },
  destroy(slug) {
    return ApiService.delete(`time_card_activities/${slug}`);
  },
  activityReport(params) {
    return ApiService.post('time_card_activities/activity_report', params);
  },
  userReport(params) {
    return ApiService.post('time_card_activities/user_report', params);
  },
};

export default TimeCardActivityService;
