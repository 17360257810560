import ApiService from './api.service';

export const TraineeRoleService = {
  query(params) {
    return ApiService.query('trainee_roles', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('trainee_roles', slug);
  },
  create(params) {
    return ApiService.post('trainee_roles', { trainee_role: params });
  },
  update(slug, params) {
    return ApiService.update('trainee_roles', slug, { trainee_role: params });
  },
  destroy(slug) {
    return ApiService.delete(`trainee_roles/${slug}`);
  },
};

export default TraineeRoleService;
