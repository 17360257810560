import ApiService from './api.service';

export const TimeCardActivityTopicService = {
  query(params) {
    return ApiService.query('time_card_activity_topics', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('time_card_activity_topics', slug);
  },
  create(params) {
    return ApiService.post('time_card_activity_topics', { time_card_activity_topic: params });
  },
  update(slug, params) {
    return ApiService.update('time_card_activity_topics', slug, { time_card_activity_topic: params });
  },
  destroy(slug) {
    return ApiService.delete(`time_card_activity_topics/${slug}`);
  },
};

export default TimeCardActivityTopicService;
