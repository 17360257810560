import ApiService from './api.service';

export const TimeCardService = {
  query(params) {
    return ApiService.query('time_cards', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('time_cards', slug);
  },
  create(params) {
    return ApiService.post('time_cards', { time_card: params });
  },
  update(slug, params) {
    return ApiService.update('time_cards', slug, { time_card: params });
  },
  destroy(slug) {
    return ApiService.delete(`time_cards/${slug}`);
  },
};

export default TimeCardService;
