<template>
  <div>
    <b-modal
      centered
      v-model="modalVisibility"
      size="xl"
      title="Trainees"
      header-bg-variant="info"
      header-text-variant="white"
      footer-bg-variant="light"
      ok-only
      ok-variant="info"
      ok-title="Close"
    >

      <div v-if="timeCardActivity && trainees && !isEditing">

        <div class="card" v-if="trainees.length > 0">

          <div class="table-responsive">
            <table class="card-table table">
              <thead>
                <tr>
                  <th class="w-25 align-middle">Name</th>
                  <th class="w-25 align-middle">Role</th>
                  <th class="w-25 align-middle">Region</th>
                  <th class="w-25 align-middle">Phase</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(trainee, index) in trainees" :key="index">
                  <td class="w-25 align-middle">
                    {{ trainee.user.first_name + " " + trainee.user.last_name }}
                  </td>
                  <td class="w-25 align-middle">
                    {{ trainee.user.trainee_role ? trainee.user.trainee_role.title : "N/A" }}
                  </td>
                  <td class="w-25 align-middle">
                    {{ trainee.user.region ? trainee.user.region.name : "N/A" }}
                  </td>
                  <td class="w-25 align-middle">
                    {{ trainee.user.phase ? trainee.user.phase.title : "N/A" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div v-else>
          <p class="center mt-2">No Trainees currently for this Time Card Activity. Start editing this Time Card to add Trainees.</p>
        </div>

      </div>

      <div v-else-if="timeCardActivity && trainees && isEditing">
        <div>
          <div id="existingUser" ref="existingUser" class="row mb-4">
            <div class="col-10 text-left">
              <div class="col-10 float-left select-bar">
                <label>Name: </label>
                <v-select class="trainee-select" :clearable="false" :options="searchResults" @search="searchTrainees" :clearSearchOnSelect="true" label="full_name" @input="createUser" :value="selectedUserValue" v-model="selectedUser" placeholder="Select a Trainee">
                  <template slot="option" slot-scope="option">
                    {{ option.full_name }} ( {{ option.trainee_role ? option.trainee_role.title : 'N/A' }}, {{ option.region ? option.region.name : 'N/A' }}, {{ option.phase ? option.phase.title : 'N/A'}} )
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-2 text-right">
              <button v-on:click="swapModal" type="button" class="btn btn-success mt-4 select-button">
                <span>Add New Trainee</span>
              </button>
            </div>
          </div>
          <div id="createUser" ref="createUser" style="display: none" class="row mb-4">
            <div  class="col-10 text-left">
              <div class="col-10 float-left select-bar">
                <div class="col-4 float-left">
                  <label class="input-label">First Name:</label>
                  <input id="first-name" ref="firstName" class="user-input" label="first_name" v-model="firstName" placeholder="Enter First Name">
                </div>
                <div class="col-4 float-left">
                  <label class="input-label">Last Name:</label>
                  <input id="last-name" ref="lastName" class="user-input" label="last_name" v-model="lastName" placeholder="Enter Last Name">
                </div>
                <div class="col-4 float-left">
                  <label class="input-label">Email:</label>
                  <input id="email" ref="email" class="user-input" label="email" v-model="email" placeholder="Enter Email">
                </div>
              </div>
              <div class="col-10 float-left select-bar">
                <div class="col-4 float-left">
                  <label for="trainee_role_select_create" class="input-label">User Role: </label>
                  <v-select id="trainee_role_select_create" ref="roleSelect" :clearable="false" :options="traineeRoles" :value="selectedRoleValue" v-model="selectedTraineeRole" :clearSearchOnSelect="true" label="title" :searchable="false" placeholder="Select a Role"/>
                </div>
                <div class="col-4 float-left">
                  <label for="region_select_create" class="input-label">Region: </label>
                  <v-select id="region_select_create" ref="regionSelect" :clearable="false" :options="regions" :value="selectedRegionValue" v-model="selectedRegion" :clearSearchOnSelect="true" label="name" :searchable="false" placeholder="Select a Region"/>
                </div>
                <div class="col-4 float-left">
                  <label for="phase_select_create" class="input-label">Phase: </label>
                  <v-select id="phase_select_create" ref="phaseSelect" :clearable="false" :options="phases" :value="selectedPhaseValue" v-model="selectedPhase" :clearSearchOnSelect="true" label="title" :searchable="false" placeholder="Select a Phase"/>
                </div>
              </div>
            </div>
            <div class="col-2 text-right buttons-col">
              <div class="col-2 text-right buttons-col">
                <button v-on:click="swapModal" type="button" class="btn btn-success mt-4 select-existing-button">
                  <span>Select Existing Trainee</span>
                </button>
              </div>
              <div class="col-2 text-right">
                <button v-on:click="createUser" type="button" class="btn btn-success mt-4 select-button">
                  <span>CreateTrainee</span>
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="card" v-if="trainees.length > 0">
          <div class="table-responsive">
            <table class="card-table table">
              <thead>
              <tr>
                <th class="w-20 align-middle">Name</th>
                <th class="w-20 align-middle">Role</th>
                <th class="w-20 align-middle">Region</th>
                <th class="w-20 align-middle">Phase</th>
                <th class="w-20 align-middle"/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(trainee, index) in trainees" :key="index" :id="'trainee-' + trainee.id">
                <td class="w-20 align-middle">
                  {{ trainee.user.first_name + " " + trainee.user.last_name }}
                </td>
                <td class="w-20 align-middle">
                  {{ trainee.user.trainee_role ? trainee.user.trainee_role.title : "N/A" }}
                </td>
                <td class="w-20 align-middle">
                  {{ trainee.user.region ? trainee.user.region.name : "N/A" }}
                </td>
                <td class="w-20 align-middle">
                  {{ trainee.user.phase ? trainee.user.phase.title : "N/A" }}
                </td>
                <td class="align-middle text-right">
                  <button v-on:click="deleteTrainee(trainee.id)" type="button" class="btn btn-danger btn-circle"><font-awesome-icon icon="times"/></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div v-else>
          <p class="center mt-2">No Trainees currently for this Time Card Activity. Use above form to add Trainees.</p>
        </div>
      </div>

      <loading-overlay v-else/>
    </b-modal>
  </div>
</template>

<script>
import { TimeCardActivityUserService } from '@/common/services/time_card_activity_user.service';
import { PhaseService } from '@/common/services/phase.service';
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import { RegionService } from '@/common/services/region.service';
import { UserService } from '@/common/services/user.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import ImpedimentHelper from '@/common/mixins/impediment_helper';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

export default {
  name: 'TraineeModal',
  mixins: [HelperMethods, ImpedimentHelper],
  components: {
    LoadingOverlay,
  },
  props: {
    timeCardActivity: Object,
    visibility: Boolean,
    isEditing: Boolean,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      trainees: null,
      filters: FilterService.defaultData(),
      modalVisibility: false,
      searchedTraineeName: null,
      searchResults: [],
      phases: null,
      traineeRoles: null,
      regions: null,
      selectedPhase: null,
      selectedPhaseValue: null,
      selectedTraineeRole: null,
      selectedRoleValue: null,
      selectedUserValue: null,
      selectedRegion: null,
      selectedRegionValue: null,
      selectedUser: null,
      createTraineeDisabled: true,
      enableTraineeAdd: false,
      disabledSelections: true,
      traineeExists: true,
      debug: false,
    };
  },
  created() {
    this.getDropdowns()
      .then((data) => {
        const [phases, traineeRoles, regions] = data;
        this.phases = phases.data;
        this.traineeRoles = traineeRoles.data.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
          if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
          return 0;
        });
        this.regions = regions.data;
        this.getUsers();
      });
  },
  watch: {
    timeCardActivity() {
      this.getTrainees();
    },
    visibility(val) {
      this.modalVisibility = val;
      this.setExisting();
    },
    modalVisibility() {
      if (this.modalVisibility === false) {
        this.$emit('onChange', this.modalVisibility);
      }
    },
    selectedUser(val) {
      if (val) {
        this.selectedTraineeRole = this.selectedUser.trainee_role ? this.selectedUser.trainee_role.title : 'N/A';
        this.selectedPhase = this.selectedUser.phase ? this.selectedUser.phase.title : 'N/A';
        this.selectedRegion = this.selectedUser.region ? this.selectedUser.region.name : 'N/A';
      }

      if (val !== '' && this.selectedRegion && this.selectedTraineeRole && this.selectedPhase) {
        this.createTraineeDisabled = false;
        this.readyToAddTrainee(true);
      } else {
        this.readyToAddTrainee(false);
      }
    },
    searchedTraineeName(val) {
      if (val !== '' && this.selectedRegion && this.selectedTraineeRole && this.selectedPhase && !this.selectedUser) {
        this.readyToAddTrainee(true);
      } else {
        this.readyToAddTrainee(false);
      }
    },
    selectedRegion(region) {
      if (region) {
        if ((this.selectedUser !== '' || this.searchedTraineeName !== '') && this.selectedRegion && this.selectedTraineeRole && this.selectedPhase) {
          this.createTraineeDisabled = false;
          this.readyToAddTrainee(true);
        } else {
          this.readyToAddTrainee(false);
        }
      } else {
        this.disabledSelections = true;
        this.createTraineeDisabled = true;
      }
    },
    selectedTraineeRole(role) {
      if (role) {
        if ((this.selectedUser !== '' || this.searchedTraineeName !== '') && this.selectedRegion && this.selectedTraineeRole && this.selectedPhase) {
          this.createTraineeDisabled = false;
          this.readyToAddTrainee(true);
        } else {
          this.readyToAddTrainee(false);
        }
      } else {
        this.disabledSelections = true;
        this.createTraineeDisabled = true;
      }
    },
    selectedPhase(phase) {
      if (phase) {
        if ((this.selectedUser !== '' || this.searchedTraineeName !== '') && this.selectedRegion && this.selectedTraineeRole && this.selectedPhase) {
          this.createTraineeDisabled = false;
          this.readyToAddTrainee(true);
        } else {
          this.readyToAddTrainee(false);
        }
      } else {
        this.disabledSelections = true;
        this.createTraineeDisabled = true;
      }
    },
  },
  methods: {
    setExisting() {
      if (this.$refs.createUser != null) {
        this.$refs.existingUser.style.display = 'flex';
        this.$refs.createUser.style.display = 'none';
      }
    },
    swapModal() {
      const existing = this.$refs.existingUser;
      const created = this.$refs.createUser;
      const temp = existing.style.display;
      existing.style.display = created.style.display;
      created.style.display = temp;
      this.clearInputs();
    },
    clearInputs() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.$refs.phaseSelect.clearSelection();
      this.$refs.regionSelect.clearSelection();
      this.$refs.roleSelect.clearSelection();
    },
    searchTrainees(search, loading) {
      // This is called when the user enters anything in the v-select field, and calls the below search method
      loading(true);
      this.searchedTraineeName = search;
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, self) => {
      const selfRef = self;

      // This method makes an api request on Tags looking for a tag name containing the search param
      let params;
      if (search.length > 0) {
        params = FilterService.build({ page: 1, per_page: 100 }, { first_name_or_last_name_cont: search, role_eq: 'Trainee' });
        selfRef.selectedPhase = null;
        selfRef.selectedTraineeRole = null;
        selfRef.selectedRegion = null;
        selfRef.traineeExists = false;
      } else {
        params = FilterService.build({ page: 1, per_page: 100 }, { s: 'created_at desc', role_eq: 'Trainee' });
        selfRef.selectedPhase = null;
        selfRef.selectedTraineeRole = null;
        selfRef.selectedRegion = null;
        selfRef.traineeExists = true;
      }
      UserService.query(params)
        .then(({ data }) => {
          selfRef.searchResults = data.sort((a, b) => {
            if (`${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}` < `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`) { return -1; }
            if (`${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}` > `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`) { return 1; }
            return 0;
          });
          if (selfRef.searchResults.length === 0) {
            selfRef.disabledSelections = false;
          }
          loading(false);
        });
    }, 350),
    getUsers() {
      this.filters = FilterService.defaultData();
      this.filters.role_eq = 'Trainee';
      const params = FilterService.build({ page: 1, per_page: 100 }, this.filters);
      UserService.query(params)
        .then(({ data }) => {
          this.searchResults = data.sort((a, b) => {
            if (`${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}` < `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`) { return -1; }
            if (`${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}` > `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`) { return 1; }
            return 0;
          });
        });
    },
    getDropdowns() {
      const params = FilterService.defaultData();
      const regionParams = { ...FilterService.defaultData(), per_page: 100 };
      const phasePromise = PhaseService.query(params);
      const traineeRolePromise = TraineeRoleService.query(params);
      const regionPromise = RegionService.query(regionParams);

      return Promise.all([phasePromise, traineeRolePromise, regionPromise]);
    },
    deleteTrainee(traineeId) {
      TimeCardActivityUserService.destroy(traineeId)
        .then(() => {
          // Remove the user from the trainees array
          this.trainees = this.trainees.filter(trainee => trainee.id !== traineeId);
          this.appendTrainees();
        });
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, (txt) => {
        const result = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        return result;
      });
    },
    createUser() {
      if (this.selectedUser) {
        const params = { user_id: this.selectedUser.id, time_card_activity_id: this.timeCardActivity.id };
        this.createNewTimeCardActivityUser(params);
      } else {
        const password = uuidv4();
        const userParams = {
          first_name: this.toTitleCase(this.firstName),
          last_name: this.toTitleCase(this.lastName),
          password,
          password_confirmation: password,
          email: this.email,
          role: 'Trainee',
          phase_id: this.selectedPhase ? this.selectedPhase.id : null,
          trainee_role_id: this.selectedTraineeRole ? this.selectedTraineeRole.id : null,
          region_id: this.selectedRegion ? this.selectedRegion.id : null,
        };

        UserService.create(userParams)
          .then((response) => {
            const params = { user_id: response.data.id, time_card_activity_id: this.timeCardActivity.id };
            this.createNewTimeCardActivityUser(params);
            this.clearInputs();
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              this.$notify({
                type: 'error',
                title: 'Error Creating User',
                text: err.response.data.error.join('<br>'),
                width: 350,
              });
            }
          });
      }

      this.checkAllRows();
    },
    createNewTimeCardActivityUser(params) {
      TimeCardActivityUserService.create(params)
        .then(() => {
          this.getTrainees();
          this.getUsers();
          this.selectedPhase = null;
          this.selectedTraineeRole = null;
          this.selectedRegion = null;
          this.selectedUser = '';
          this.disabledSelections = true;
          this.selectedUser = null;
          this.disabledSelections = false;
          this.createTraineeDisabled = true;
          this.searchedTraineeName = '';
          this.selectedUserValue = '';
          this.traineeExists = true;
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            this.$notify({
              type: 'error',
              title: 'Error Adding Trainee',
              text: err.response.data.error[0],
              width: 350,
            });
          }
        });
    },
    getTrainees() {
      if (this.timeCardActivity && this.timeCardActivity.id) {
        this.filters.time_card_activity_id_eq = this.timeCardActivity.id;
        this.filters.role_eq = 'Trainee';
        const params = FilterService.build({ page: 1, per_page: 1000 }, this.filters);
        TimeCardActivityUserService.query(params)
          .then((response) => {
            this.trainees = response.data;
            this.appendTrainees(response.data);
          });
      }
    },
    readyToAddTrainee(exists) {
      const recordExists = exists;

      if (recordExists && this.selectedUser) {
        this.selectedTraineeRole = this.selectedUser.trainee_role ? this.selectedUser.trainee_role.title : 'N/A';
        this.selectedPhase = this.selectedUser.phase ? this.selectedUser.phase.title : 'N/A';
        this.selectedRegion = this.selectedUser.region ? this.selectedUser.region.name : 'N/A';
        this.disabledSelections = true;
        this.createTraineeDisabled = false;
      } else if (recordExists) {
        this.createTraineeDisabled = false;
      } else {
        this.disabledSelections = false;
        this.createTraineeDisabled = true;
      }
    },
    appendTrainees(trainees = this.trainees) {
      const users = [];
      for (let i = 0; i < trainees.length; i += 1) {
        users.push(trainees[i].user);
      }
      this.log('traineeModal -> appendTrainees', { trainees, users });
      this.timeCardActivity.trainees = _.cloneDeep(users);
    },
    updateImpedimentBlocks() {},
  },
};
</script>

<style lang="scss" scoped>

  .input-label{
    margin-bottom: 2px;
    margin-top: 8px;
  }

  .user-input {
    width: 265px;
    border: 1px solid rgba(60,60,60,.26);
    border-radius: 3px;
    height: 33px;
    text-indent: 6px;
  }

  .buttons-col {
    padding-left: 0px;
    padding-right: 0px;
  }

  .select-existing-button {
    font-size: 12px;
    width: 161px;
    padding-left: 12px;
    border-left-width: 0px;
    margin-top: -10px !important;
    margin-bottom: 11px;
  }

  .select-button {
    font-size: 14px;
    margin-top: 31px !important;
  }

  .select-bar {
    max-width: 100%;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 4px 1px 4px 1px;
    border-radius: 15px;
    text-align: center;
    font-size: 16px;
    line-height: 0.42857;
  }

  .v-select.single.searching:not(.open):not(.loading) input[type=search] {
    opacity: 1 !important;
    color: red;
  }
</style>
