<template>
  <div>
    <b-modal
      centered
      v-model="modalVisibility"
      size="xl"
      title="Audiences"
      header-bg-variant="info"
      header-text-variant="white"
      footer-bg-variant="light"
      ok-only
      ok-variant="info"
      ok-title="Close"
    >

      <div v-if="isLoaded">

        <div v-if="isEditing">

          <div v-if="timeCardActivity && topics">
            <v-select :options="scopedTopics" @search="searchTopics" :searchable="false" :closeOnSelect="true" :clearSearchOnSelect="false" :taggable="false" :push-tags="true" label="title" v-model="selected" multiple  placeholder="Please select an audience"></v-select>

            <div class="table-responsive">
              <table class="card-table table">
                <thead>
                <tr>
                  <th class="w-20 align-middle">Audience</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(topic, index) in selected" :key="index" :id="'topic-' + topic.id">
                  <td class="w-20 align-middle">
                    {{ topic.title }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div v-else>

          <div class="card" v-if="timeCardActivity.topics && timeCardActivity.topics.length > 0">
            <div class="table-responsive">
              <table class="card-table table">
                <thead>
                <tr>
                  <th class="w-25 align-middle">Title</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(topic, index) in timeCardActivity.topics" :key="index">
                  <td class="w-25 align-middle">
                    {{ topic.title}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            <p class="center mt-2">No Topics currently for this Time Card Activity. Start editing this Time Card to add Topics.</p>
          </div>

        </div>

      </div>

      <loading-overlay v-else />
    </b-modal>
  </div>
</template>

<script>
import { TimeCardActivityTopicService } from '@/common/services/time_card_activity_topic.service';
import { FilterService } from '@/common/services/filter.service';
import JwtService from '@/common/services/jwt.service';
import PermissionsService from '@/common/services/user_permissions.service';
import { ActivityTopicService } from '@/common/services/activity_topic.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import HelperMethods from '@/common/mixins/helper_methods';
import ImpedimentHelper from '@/common/mixins/impediment_helper';
import _ from 'lodash';

export default {
  name: 'TopicModal',
  mixins: [HelperMethods, ImpedimentHelper],
  components: {
    LoadingOverlay,
  },
  props: {
    timeCardActivity: Object,
    visibility: Boolean,
    isEditing: Boolean,
    allTopics: Array,
  },
  data() {
    return {
      isLoaded: false,
      modalVisibility: false,
      topics: [],
      scopedTopics: [],
      filters: FilterService.defaultData(),
      selected: [],
      jwt: JwtService.parseJwt(),
      isSalesCoach: false,
      isServiceCoach: false,
      Deselect: {
        render: createElement => createElement('span', {
          on: {
            click: () => {},
          },
        }),
      },
      previouslySelected: [],
      debug: false,
    };
  },
  mounted() {
    if (PermissionsService.isSalesCoach(this.jwt.role)) {
      this.isSalesCoach = true;
    }
    if (PermissionsService.isServiceCoach(this.jwt.role)) {
      this.isServiceCoach = true;
    }
    this.reset();
  },
  watch: {
    timeCardActivity(newVal) {
      this.reset();
      this.log('topicModal -> watch timeCardActivity', newVal);
      if (this.isNotEmpty(newVal)) {
        this.$nextTick(() => {
          if (this.isEditing) {
            this.selectedTopics();
          } else {
            this.isLoaded = true;
          }
        });
      }
    },
    visibility(val) {
      this.reset();
      this.modalVisibility = val;
    },
    modalVisibility() {
      this.reset();
      if (this.modalVisibility === false) {
        this.$emit('onChange', this.modalVisibility);
        this.checkAllRows();
      }
    },
    selected(selected) {
      if (this.previouslySelected !== this.selected) {
        if (selected.length > 0) {
          if (this.previouslySelected.length > 0 && this.selected.length > 0) {
            _.forEach(this.previouslySelected, (val, index) => {
              if (this.previouslySelected.length >= this.selected.length) {
                if (val !== selected[index]) {
                  this.previouslySelected.splice(index, 1);
                  this.deleteTopic(val);
                }
              } else {
                this.saveTopic(selected[selected.length - 1]);
                this.previouslySelected.push(selected[selected.length - 1]);
              }
            });
          } else {
            // create first record
            this.saveTopic(selected[selected.length - 1]);
            this.previouslySelected.push(selected[selected.length - 1]);
          }
        } else if (this.previouslySelected.length > 0) {
          // delete the last previouslySelected;
          this.deleteTopic(this.previouslySelected[this.previouslySelected.length - 1]);
          this.previouslySelected = [];
        }
      }
    },
  },
  methods: {
    reset() {
      this.isLoaded = false;
      this.topics = [];
      this.scopedTopics = [];
      this.filters = FilterService.defaultData();
      this.selected = [];
      this.previouslySelected = [];
    },
    searchTopics(search, loading) {
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, self) => {
      const selfRef = self;
      selfRef.selectedTopics = search;
    }),
    selectedTopics() {
      if (this.timeCardActivity && this.timeCardActivity.id) {
        this.filters.time_card_activity_id_eq = this.timeCardActivity.id;
        const params = FilterService.build({ page: 1, per_page: 10000 }, this.filters);
        this.getActivityTopics()
          .then(() => TimeCardActivityTopicService.query(params))
          .then((response) => {
            this.log('topicModal -> selectedTopics -> TimeCardActivityTopicService.query', { response, params });
            _.forEach(response.data, (v) => {
              _.forEach(this.allTopics, (value) => {
                if (v.topic_id === value.id) {
                  this.topics.push(value);
                }
              });
            });
            this.selected = _.uniq(this.topics);
            this.previouslySelected = _.uniq(this.topics);
            this.appendTopics();
            this.isLoaded = true;
          });
      }
    },
    saveTopic(topic) {
      const selectedTopic = {
        topic_id: topic.id,
        time_card_activity_id: this.timeCardActivity.id,
      };
      TimeCardActivityTopicService.create(selectedTopic)
        .then((response) => {
          this.log('topicModal -> saveTopic -> topic saved', response);
          this.appendTopics();
        });
    },
    deleteTopic(topic) {
      const filters = FilterService.defaultData();
      filters.time_Card_activity_id_eq = this.timeCardActivity.id;
      filters.topic_id_eq = topic.id;
      const params = FilterService.build({ page: 1, per_page: 1 }, filters);
      TimeCardActivityTopicService.query(params)
        .then(response => TimeCardActivityTopicService.destroy(response.data[0].id))
        .then((response) => {
          this.log('topicModal -> deleteTopic => topic destroyed', response);
          this.appendTopics();
        });
    },
    appendTopics() {
      this.log('topicModal -> appendTopics', this.selected);
      this.timeCardActivity.topics = _.cloneDeep(this.selected);
      this.checkAllRows();
    },
    getActivityTopics() {
      if (this.$parent.user.role === 'Sales Coach') {
        this.isSalesCoach = true;
      }
      if (this.$parent.user.role === 'Service Coach') {
        this.isServiceCoach = true;
      }
      const params = {
        activity_id: this.timeCardActivity.activity_id,
        service_coach: this.isServiceCoach,
        sales_coach: this.isSalesCoach,
        per_page: 1000,
      };
      this.log('topicModal -> getActivityTopics', params);
      return ActivityTopicService.query(params)
        .then((response) => {
          this.log('topicModal -> getActivityTopics -> ActivityTopicService.query', response);
          const activityTopics = response.data;
          const topicIds = activityTopics.map(activityTopic => activityTopic.topic_id);
          this.log('topicModal -> getActivityTopics -> ActivityTopicService.query -> topicIds', topicIds);
          for (let i = 0; i < this.allTopics.length; i += 1) {
            const topic = this.allTopics[i];
            if (topic && topicIds.includes(topic.id)) {
              this.scopedTopics.push(topic);
            }
          }
          this.log('topicModal -> getActivityTopics -> ActivityTopicService.query -> scopedTopics', this.scopedTopics);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.topics {
  padding: 0;
  columns: 3;
  display: block;
  list-style: none;

  li {
    position: relative;
    display: flex;
    text-align: left;
    word-wrap: break-word;
    justify-content: space-between;

    p {
      margin: 0;
    }

    input {
      justify-content: flex-end;
      right: 10px;
    }
  }
}
</style>
