import ApiService from './api.service';

export const ActivityTopicService = {
  query(params) {
    return ApiService.query('activity_topics', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('activity_topics', slug);
  },
  create(params) {
    return ApiService.post('activity_topics', { activity_topic: params });
  },
  update(slug, params) {
    return ApiService.update('activity_topics', slug, { activity_topic: params });
  },
  destroy(slug) {
    return ApiService.delete(`activity_topics/${slug}`);
  },
};

export default ActivityTopicService;
