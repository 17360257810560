import Vue from 'vue';
import uuidv4 from 'uuid/v4';
import { ActivityService } from '@/common/services/activity.service';
import { FilterService } from '@/common/services/filter.service';
import _ from 'lodash';

export default {
  data() {
    return {
      impediments: [],
      activities: [],
      activitiesWithImpediments: [],
      filters: FilterService.defaultData(),
      debug: false,
    };
  },
  components: {
    ActivityService,
  },
  created() {
    this.getActivitiesForImpediments();
  },
  mounted() {
    this.checkForImpediments();
  },
  methods: {
    /**
     * Add a standard impediment to our growing list
     * @param activityUuid
     * @param rowUuid
     * @returns {*}
     */
    addImpediment(activityUuid, rowUuid) {
      const uuid = uuidv4();
      const impediment = {
        uuid,
        activity_uuid: activityUuid,
        row_uuid: rowUuid,
      };
      this.addToImpediments(impediment);
      return uuid;
    },
    /**
     * Remove an impediment from our list
     * @param uuid
     */
    removeImpediment(uuid) {
      this.log('removeImpediment', uuid);
      const impediments = this.getImpediments();
      if (impediments && impediments.length && this.checkIfImpedimentExists(uuid)) {
        for (let i = 0; i < impediments.length; i += 1) {
          const impediment = impediments[i];
          if (impediment && impediment.uuid === uuid) {
            impediments.splice(i, 1);
            break;
          }
        }
      }
      this.writeToImpediments(impediments);
    },
    /**
     * Add an impediment to an already existing list
     * @param impediment
     */
    addToImpediments(impediment) {
      this.log('addToImpediments', impediment);
      const impediments = this.getImpediments();
      if (impediments.length >= 0) {
        if (this.checkIfImpedimentExists(impediment.uuid) === false) {
          impediments.push(impediment);
        }
      }
      this.writeToImpediments(impediments);
    },
    /**
     * Write the impediments to a global shared in the timecard
     * @param impediments
     */
    writeToImpediments(impediments) {
      const uniqueImpediments = _.uniqBy(impediments, 'row_uuid');
      this.log('writeToImpediments -> uniqueImpediments', uniqueImpediments);
      Vue.prototype.$impediments = uniqueImpediments;
    },
    getImpediments() {
      let impediments = [];
      if (Vue.prototype.$impediments && Vue.prototype.$impediments.length > 0) {
        impediments = Vue.prototype.$impediments;
      }
      this.log('getImpediments', impediments);
      return impediments;
    },
    /**
     * Checks if impediment exists by uuid
     * @param uuid
     * @returns {boolean}
     */
    checkIfImpedimentExists(uuid) {
      let result = false;
      const impediment = this.findObjectsInArrayByProp('uuid', uuid);
      if (impediment.length) {
        result = true;
      }
      return result;
    },
    /**
     * Checks if impediment exists by activity uuid
     * @param activityUuid
     * @returns {boolean}
     */
    checkIfImpedimentExistsByActivity(activityUuid) {
      let result = false;
      const impediment = this.findObjectsInArrayByProp('uuid', activityUuid, this.activitiesWithImpediments);
      if (impediment.length) {
        result = true;
      }
      return result;
    },
    /**
     * Generic find all objects in an array by a property matching a value
     * @param property
     * @param value
     * @param array
     * @returns {[]}
     */
    findObjectsInArrayByProp(property, value, array = Vue.prototype.$impediments) {
      const result = [];
      for (let i = 0; i < array.length; i += 1) {
        if (array[i][property] === value) {
          result.push(array[i]);
        }
      }
      this.log('findObjectsInArrayByProp', {
        property,
        value,
        array,
        result,
      });
      return result;
    },
    /**
     * Check if the a given activity has an impediment
     * @param activityUuid
     * @param rowUuid
     */
    doesActivityHaveAnImpediment(activityUuid, rowUuid) {
      if (this.checkIfImpedimentExistsByActivity(activityUuid)) {
        this.addImpediment(activityUuid, rowUuid);
      } else {
        this.removeAllImpedimentsFromRow(rowUuid);
      }
      this.clearSatisfiedImpediments(rowUuid);
      this.log('doesActivityHaveAnImpediment', { activityUuid, rowUuid });
    },
    /**
     * Clear a row of all previous impediments
     * @param rowUuid
     */
    removeAllImpedimentsFromRow(rowUuid) {
      const impediments = this.findObjectsInArrayByProp('row_uuid', rowUuid);
      if (impediments.length > 0) {
        for (let i = 0; i < impediments.length; i += 1) {
          const impediment = impediments[i];
          this.removeImpediment(impediment.uuid);
        }
      }
      this.log('removeAllImpedimentsFromRow', rowUuid);
    },
    /**
     * Get all activities then make a sub list of activities that have impediments
     * @param page
     * @param perPage
     */
    getActivitiesForImpediments(page = 1, perPage = 1000) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      ActivityService.query(params).then((response) => {
        this.log('getActivitiesForImpediments', response.data);
        this.activities = response.data;
        this.activitiesWithImpediments = this.findObjectsInArrayByProp('coach_impediment', true, this.activities);
        this.activitiesWithImpediments = this.activitiesWithImpediments.concat(this.findObjectsInArrayByProp('topic_impediment', true, this.activities));
        this.checkForImpediments();
      });
    },
    /**
     * Find the UUID of an activity by it's id
     * @param id
     * @returns {null}
     */
    findActivityUuidById(id) {
      let uuid = null;
      let activity = null;
      for (let i = 0; i < this.activities.length; i += 1) {
        if (this.activities[i].id === id) {
          activity = this.activities[i];
          break;
        }
      }
      if (activity) {
        // eslint-disable-next-line
        uuid = activity.uuid;
      }
      this.log('findActivityUuidById', { id, uuid });
      return uuid;
    },
    /**
     * Check if the activity associated with this time card activity has an impediment
     */
    checkForImpediments(uuid = this.uuid) {
      this.log('checkForImpediments', { uuid, activities: this.activities, timeCardActivity: this.timeCardActivity });
      if (this.activities && this.activities.length > 0 && this.timeCardActivity && uuid) {
        this.removeAllImpedimentsFromRow(uuid);
        const activityUuid = this.findActivityUuidById(this.timeCardActivity.activity_id);
        this.doesActivityHaveAnImpediment(activityUuid, uuid);
      }
    },
    /**
     * Check if requirements are met, and if they are nuke all row impediments
     * @param rowUuid
     */
    clearSatisfiedImpediments(rowUuid) {
      this.log('clearSatisfiedImpediments', rowUuid);
      if (this.checkIfImpedimentIsSatisfied(rowUuid)) {
        this.log('clearSatisfiedImpediments -> clearing', rowUuid);
        this.removeAllImpedimentsFromRow(rowUuid);
      }
    },
    /**
     * Depending on the logic, determine if the impediments are met
     * @param rowUuid
     * @returns {boolean}
     */
    checkIfImpedimentIsSatisfied(rowUuid) {
      let result = false;
      const impediments = this.findObjectsInArrayByProp('row_uuid', rowUuid);
      if (impediments && impediments.length > 0 && this.timeCardActivity) {
        this.log('checkIfImpedimentIsSatisfied', { impediments, timeCardActivity: this.timeCardActivity });
        let traineeImpedimentMet = !this.timeCardActivity.activity.coach_impediment;
        let activityImpedimentMet = !this.timeCardActivity.activity.topic_impediment;

        // Check for at least 1 trainee
        if (this.timeCardActivity.trainees && this.timeCardActivity.trainees.length > 0) {
          traineeImpedimentMet = true;
        }

        // Check for at least 1 topic
        if (this.timeCardActivity.topics && this.timeCardActivity.topics.length > 0) {
          activityImpedimentMet = true;
        }

        if (traineeImpedimentMet && activityImpedimentMet) {
          result = true;
        }
        this.log('checkIfImpedimentIsSatisfied -> end', { result, rowUuid, traineeImpedimentMet });
      }
      return result;
    },
    /**
     * by simply checking the length of the array see if all impediments are met
     * @returns {boolean}
     */
    areAllImpedimentsMet() {
      let result = false;
      const impediments = this.getImpediments();
      if (impediments.length === 0) {
        result = true;
      }
      return result;
    },
    /**
     * Get simple list of titles of all activities with an impediment
     * @returns {[]}
     */
    getListOfActivitiesInvolvingTrainees() {
      const result = [];
      this.log('getListOfActivitiesInvolvingTrainees', this.activitiesWithImpediments);
      for (let i = 0; i < this.activitiesWithImpediments.length; i += 1) {
        result.push(this.activitiesWithImpediments[i].title);
      }
      this.highLightRowWithActiveImpediment();
      return result;
    },
    /**
     * This gives a nice little red flash for 2 seconds on the row that's bad
     */
    highLightRowWithActiveImpediment() {
      const impediments = this.getImpediments();
      for (let i = 0; i < impediments.length; i += 1) {
        const impediment = impediments[i];
        const selector = `[data-uuid="${impediment.row_uuid}"]`;
        const targetRow = document.querySelectorAll(selector);
        if (targetRow && targetRow.length > 0) {
          this.log('highLightRowWithActiveImpediment -> row', { row: targetRow, selector });
          // querySelectorAll returns an array.
          targetRow[0].classList.add('flash-warn');
          setTimeout(() => {
            targetRow[0].classList.remove('flash-warn');
          }, 3000);
        }
      }
    },
    registerRow(uuid) {
      this.log('registerRow', uuid);
      let registry = [];
      registry = this.rowRegistryRecords();
      if (uuid) {
        this.deregisterRow(uuid);
        if (registry && registry.length >= 0) {
          registry.push(uuid);
        }
        this.writeToRowRegistry(registry);
      }
    },
    deregisterRow(uuid) {
      if (uuid) {
        this.log('deregisterRow - if exists', uuid);
        this.removeAllImpedimentsFromRow(uuid);
        const newRegistry = this.findAndSpliceValueFromArray(this.rowRegistryRecords(), uuid);
        this.writeToRowRegistry(newRegistry);
      }
    },
    writeToRowRegistry(registry) {
      this.log('writeToRowRegistry', registry);
      Vue.prototype.$rowRegistry = registry;
    },
    rowRegistryRecords() {
      let registry = [];
      if (Vue.prototype.$rowRegistry && Vue.prototype.$rowRegistry.length > 0) {
        registry = Vue.prototype.$rowRegistry;
      }
      this.log('rowRegistryRecords', registry);
      return registry;
    },
    checkAllRows() {
      // this.$eventHub.$emit('impedimentCallback');
      const rowUuids = this.rowRegistryRecords();
      this.log('checkAllRows', rowUuids);
      if (rowUuids && rowUuids.length > 0) {
        for (let i = 0; i < rowUuids.length; i += 1) {
          const uuid = rowUuids[i];
          this.$eventHub.$emit(uuid);
        }
      }
    },
  },
};
